import React from "react"

import Player from "./player"

const Video = ({ src, caption, thumbnail }) => (
  <div className="video three">
    <Player src={src} thumbnail={thumbnail} />
    <p>{caption}</p>
  </div>
)

export default Video
